import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseService } from '../../base/services/base.service';
import { Crs } from '../models/crs.model';

@Injectable({
    providedIn: 'root',
})
export class CrsService extends BaseService<Crs> {
    constructor(public http: HttpClient) {
        super(http)
    }

    crsList() {
        return this.http.get<Crs[]>(environment.serverUrl + 'crs')
    }

    isUsuarioCRS() {
        return this.http.get<boolean>(environment.serverUrl + 'menu/is-usuario-crs')
    }

    public getPathModule(): string {
        return 'crs/'
    }
}
