import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { EMPTY, Observable, throwError } from 'rxjs'
import { catchError, finalize } from 'rxjs/operators'
import { BaseFormComponent } from 'src/app/modules/base/components/base-form.component'
import { BaseRoute } from 'src/app/modules/base/models/base-route.model'
import { CommonConfirmDialogComponent } from 'src/app/shared/components/common-confirm-dialog/common-confirm-dialog.component'
import { CommonStatusDialogComponent } from 'src/app/shared/components/common-status-dialog/common-status-dialog.component'
import { ConfirmDialogData } from 'src/app/shared/models/confirm-dialog-data.model'
import { StatusDialog } from 'src/app/shared/models/status-dialog.model'
import { AlfrescoFileNameLengthError, AlfrescoFileSizeError, AlfrescoService } from 'src/app/shared/services/alfresco.service'
import { CpfValidator } from 'src/app/validators/cpf-validator'
import { CodigoDescricaoDTO } from '../../../dto/codigo-descricao'
import { UsuarioGestorDTO } from '../../../dto/usuario-gestor.dto'
import { Municipio } from '../../../models/municipio.model'
import { GestorService } from '../../../services/gestor.service'
import { MunicipiosService } from '../../../services/municipios.service'
import { GestorVinculosDialogComponent, VinculosRemoverFormDTO } from '../../components/gestor-vinculos-dialog/gestor-vinculos-dialog.component'
import { GestorConstant } from '../../constants/gestor.constant'

@Component({
    selector: 'app-gestor-form',
    templateUrl: './gestor-form.component.html',
    styleUrls: ['./gestor-form.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class GestorFormComponent extends BaseFormComponent<UsuarioGestorDTO, GestorService> implements OnInit {
    title: string = GestorConstant.TITLE_CADASTRO_GESTOR
    subtitle: string = GestorConstant.SUBTITLE_NEW_GESTOR
    form: FormGroup
    municipios: any[]
    municipiosVinculados: CodigoDescricaoDTO[] = []
    isMaxMunicipiosVinculados: boolean = false;
    vinculosRemover: VinculosRemoverFormDTO;
    gestorResponsavel: any[] = null
    confirmCancelarDialogData: ConfirmDialogData = GestorConstant.CONFIRM_DIALOG_CANCELAR_GESTOR
    confirmVinculosDialogData: ConfirmDialogData = GestorConstant.CONFIRM_DIALOG_VINCULOS_GESTOR;
    maxVinculosDialogData: ConfirmDialogData = new ConfirmDialogData(null, '');
    @ViewChild('statusDialog') statusDialog: CommonStatusDialogComponent
    @ViewChild('confirmCancelarDialog') confirmCancelarDialog: CommonConfirmDialogComponent
    @ViewChild('confirmDialog') confirmDialog: CommonConfirmDialogComponent
    @ViewChild('confirmEnviarDialog') confirmEnviarDialog: CommonConfirmDialogComponent
    @ViewChild('fileRejected') fileRejected: CommonStatusDialogComponent;
    @ViewChild('maxVinculosDialog') maxVinculosDialog: GestorVinculosDialogComponent;
    @ViewChild('confirmVinculosDialog') confirmVinculosDialog: CommonConfirmDialogComponent

    mensagem = {
        nome: [{ tipo: 'minLength', mensagem: 'O Campo nome precisa ter pelo menos 3 caracteres!' }],
        cpf: [{ tipo: 'invalido', mensagem: 'CPF inválido!' }],
        email: [{ tipo: 'email', mensagem: 'E-mail invalido' }],
    }

    arquivo: any
    carregando: boolean = false

    msgErroArquivo: string = 'Preenchimento obrigatório'
    flagErroArquivo: boolean = false
    displayModalCpfCadastradoMunicipio: boolean
    displayModalAnexoOk: boolean
    displayModal: boolean

    constructor(
        public router: Router,
        public activateRoute: ActivatedRoute,
        public gestorService: GestorService,
        private municipioService: MunicipiosService,
        private alfrescoService: AlfrescoService
    ) {
        super(router, activateRoute, BaseRoute.of('/gestores/gestor'), gestorService)
    }

    public salvar(): void {
        this.router.navigate(['/gestores/lista de gestor', 'new'])
    }

    ngOnInit(): void {
        this.initForm()
        this.setDataFormEdit()
        this.getMunicipios()
    }

    showModalDialogConfirm = () => {
        if (this._validarFormulario()) {
            this.displayModal = true
        }
    }

    carregar(value: boolean) {
        this.carregando = value
    }

    onPressSend() {
        if (!this._validarFormulario()) {
            return;
        }

        const cpf = this.form.get('cpf').value;
        const municipio = this.form.get('municipio').value;
        this.carregar(true);
        this.getVinculosMunicipios(cpf.trim())
            .pipe(finalize(()=> this.carregar(false)))
            .subscribe((resp) => {
                const vinculos = resp.length;
                if (vinculos == 0) {
                    this.isMaxMunicipiosVinculados = false;
                    this.showModalDialogConfirm();
                } else if (resp.filter((m) => municipio.codigoIbge == m.codigo).length > 0 ? true : false) {
                    this.displayModalCpfCadastradoMunicipio = true;
                } else if (vinculos < GestorConstant.MAX_MUNICIPIOS_VINCULADOS) {
                    this.isMaxMunicipiosVinculados = false;
                    this.openConfirmDialogVinculos(vinculos);
                } else {
                    this.isMaxMunicipiosVinculados = true;
                    this.openVinculosMaximoDialog(vinculos, resp);
                }
            })

    }

    onConfirmSend = () => {
        this.displayModal = false
        this.save()
    }

    generateVinculoMessage(qtdVinculos:number): string {
        const extenso:string[] = [
            'nenhum','um','dois','três','quatro','cinco',
            'seis','sete','oito','nove','dez'
        ];
        const numeroTexto = extenso[qtdVinculos];
        let message = `Você já está vinculado a ${qtdVinculos}`;
        if(numeroTexto) {
            message += ` (${extenso[qtdVinculos]})`
        }
        message += qtdVinculos == 1 ? ' município.' : ' municípios.';
        return message;
    }

    openConfirmDialogVinculos(qtdVinculos:number) {
        const message = this.generateVinculoMessage(qtdVinculos)
        this.confirmVinculosDialogData = new ConfirmDialogData(null, `${message} Deseja continuar?`)
        this.displayModal = false;
        this.confirmVinculosDialog.openDialog();
    }

    openVinculosMaximoDialog(max:number, municipiosVinculados:CodigoDescricaoDTO[]) {
        this.displayModal = false;
        const message = this.generateVinculoMessage(max)
        this.maxVinculosDialogData = new ConfirmDialogData(null, `${message} Esse é o limite máximo.`, 'Enviar', 'Fechar')
        this.municipiosVinculados = municipiosVinculados;
        this.isMaxMunicipiosVinculados = true;
        this.maxVinculosDialog.openDialog();
    }

    getVinculosMunicipios(cpf:string): Observable<any[]>{
        return this.gestorService.municipiosVinculados(cpf);
    }

    getMunicipios() {
        this.municipioService.todosMunicipiosList().subscribe((municipios) => {
            this.municipios = municipios
        })
    }

    initForm() {
        this.form = new FormGroup({
            nome: new FormControl('', [Validators.required, Validators.minLength(3)]),
            cpf: new FormControl('', [Validators.required, CpfValidator.cpfValido]),
            rg: new FormControl(''),
            telefone: new FormControl('', [Validators.required]),
            telefoneAlternativo: new FormControl(''),
            email: new FormControl('', [Validators.required, Validators.email]),
            formacao: new FormControl('', [Validators.required]),
            cargo: new FormControl('', [Validators.required]),
            municipio: new FormControl('', [Validators.required]),
            nomeCrs: new FormControl('', [Validators.required]),
            idDocumentoAlfresco: new FormControl(null, [Validators.required]),
            nomeSecretario: new FormControl('', [Validators.required, Validators.minLength(3)]),
            telefoneSecretario: new FormControl('', [Validators.required, Validators.min(1000000000)]),
            emailSecretario: new FormControl('', [Validators.required, Validators.email]),
            ine: new FormControl('', [Validators.required, Validators.max(9999999999), Validators.min(0)]),
        })
        this.form.get('municipio').valueChanges.subscribe((v) => this.setNomeCrs(v));

    }

    private setNomeCrs(v: Municipio) {
        this.form.get('nomeCrs').setValue(v.crs, { onlySelf: false, emitEvent: false });//?.nome
    }

    getDataCreate(): UsuarioGestorDTO {
        const data = new UsuarioGestorDTO()
        data.nome = this.form.get('nome').value
        data.cpf = this.form.get('cpf').value
        data.rg = this.form.get('rg').value
        data.telefone = this.form.get('telefone').value
        data.telefoneAlternativo = this.form.get('telefoneAlternativo').value
        data.email = this.form.get('email').value
        data.formacao = this.form.get('formacao').value
        data.cargo = this.form.get('cargo').value
        data.idMunicipio = this.form.get('municipio').value ? this.form.get('municipio').value.id : null
        data.idDocumentoAlfresco = this.form.get('idDocumentoAlfresco').value
        data.nomeSecretarioPendente = this.form.get('nomeSecretario').value
        data.telefoneSecretarioPendente = this.form.get('telefoneSecretario').value
        data.emailSecretarioPendente = this.form.get('emailSecretario').value
        data.ine = this.form.get('ine').value


        // Adicionar informação sobre municipios vinculados a remover
        if(this.isMaxMunicipiosVinculados) {
            data.listaMunicipiosParaDesvincular = this.vinculosRemover.municipios;
            data.observacaoDesvincularMunicipios = this.vinculosRemover.observacao;
        } else {
            data.listaMunicipiosParaDesvincular = []
        }
        return data
    }

    setDateEdit(date: Date) {
        const d = new Date(date)
        const fomartDate =
            d.getFullYear().toString() + '-' + (d.getMonth() + 1).toString().padStart(2, '0') + '-' + d.getDate().toString().padStart(2, '0')
        return fomartDate
    }
    setDataFormEdit() {
        // this.form.patchValue({ ...this.entity });
        // this.form.patchValue({ dataInicioUso: this.setDateEdit(this.entity.dataInicioUso) });
        // this.form.updateValueAndValidity();
        // this.form.controls['gestor'].disable();
        // this.title = GestorConstant.TITLE_CADASTRO_GESTOR
        // this.subtitle = GestorConstant.SUBTITLE_EDIT_GESTOR
    }

    showMessageSuccess(entity: UsuarioGestorDTO) {
        this.statusDialog.openDialog(StatusDialog.SALVO)
    }

    openConfirmCancelarDialog() {
        this.confirmCancelarDialog.openDialog()
    }

    openConfirmEnviarDialog() {
        this.confirmEnviarDialog.openDialog()
    }

    onChange() {
        console.log(this.form.get('gestorResponsavel').value)
    }

    limparForm = () => {
        this.router.navigate(['unauthorized'])
    }

    fieldMessageRequired(field): boolean {
        return this.form.get(field).invalid && this.form.get(field).dirty
    }

    public getCaracteres(field: string): number {
        const value = String(this.form.get(field).value)
        return value ? value.length : 0
    }

    alphaOnly(event) {
        const key = event.key
        const numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
        return !(numbers.indexOf(key) > -1)
    }

    /* Metodo de chamada de dialog para o botão fechar */
    public cancelar(): void {
        let isShow = true
        for (const field in this.form.controls) {
            if (this.form.get(field).value != '' && this.form.get(field).value != null) {
                isShow = false
            }
        }
        if (isShow) {
            this.router.navigate(['/unauthorized'])
        } else {
            this.openConfirmCancelarDialog()
        }
    }

    uploadArquivo(listFile: FileList) {
        if (listFile.length) {
            if (listFile[0].type === 'application/pdf') {
                this.carregar(true)
                this.alfrescoService
                    .uploadArquivoAlfresco(listFile[0])
                    .pipe(
                        catchError((e) => {
                            if(e instanceof AlfrescoFileNameLengthError) {
                                this.fileRejected.openDialog(StatusDialog.ARQUIVO_NOME_TAMANHO_INVALIDO);
                                return EMPTY;
                            }
                            if(e instanceof AlfrescoFileSizeError){
                                this.fileRejected.openDialog(StatusDialog.ARQUIVO_TAMANHO_INVALIDO);
                                return EMPTY;
                            }
                            return throwError(e)
                        }),
                        finalize(() => (this.carregar(false))))
                    .subscribe((res) => {
                        this.arquivo = res
                        this.form.get('idDocumentoAlfresco').setValue(res.id)
                        this.flagErroArquivo = false
                        this.displayModalAnexoOk = true
                    })
            } else {
                this.erroArquivoInvalido()
            }
        } /*  else {
            this.excluirArquivo()
        } */
    }

    erroArquivoInvalido() {
        this.msgErroArquivo = 'Formato do arquivo não permitido (aceito somente arquivo PDF)'
        this.flagErroArquivo = true
    }

    downloadArquivo() {
        this.alfrescoService.downloadArquivoAlfresco(this.arquivo.id).subscribe((res) => {
            this.createAndDownloadBlobFile(res, this.arquivo.nome)
        })
    }

    private createAndDownloadBlobFile(body, filename) {
        const blob = new Blob([body])
        const link = document.createElement('a')
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob)
            link.setAttribute('href', url)
            link.setAttribute('download', filename)
            link.style.visibility = 'hidden'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        }
    }

    excluirArquivo() {
        this.arquivo = null
        this.form.get('idDocumentoAlfresco').setValue(null)
        const item = <HTMLInputElement>document.getElementById('formFile')
        item.value = null
        this.form.get('idDocumentoAlfresco').markAsDirty()
        this.msgErroArquivo = 'Preenchimento obrigatório'
        this.flagErroArquivo = false
    }
}
