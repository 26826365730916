// Para fazer o Deploy/Release da aplicação:
// Executar o comando:
// ng build -c prod --base-href
// Copiar o conteúdo gerado na pasta `dist` do projeto.
//
export const environment = {
    production: true,

    // Configurações SOE:
    soeAuth: 'https://www.soe.rs.gov.br/soeauth/.well-known/openid-configuration',
    soeProfile: 'https://www.soe.rs.gov.br/soeauth/profile.xhtml',
    soeClientId: 'sisrbc.i2.dJDbfgkYB7gJ2IZnmGHu',

    // Configurações Aplicação:
    serverUrl: 'https://secweb.procergs.com.br/sisrbc/rest/',
    clientUrl: 'http://sisrbc.rs.gov.br/'
};
