<common-page [title]="title" [subtitle]="subtitle">
    <div class="{{ carregando ? 'loading-content' : '' }}">
        <div class="formulario">
            <form [formGroup]="form">
                <div class="form">
                    <h2>Enviar ofício/solicitar acesso</h2>
                    <hr />
                    <div class="oficio">
                        <p><a href="assets/documents/Modelo_oficio_RBC.docx" download>Clique aqui</a> para fazer o download do modelo de ofício</p>
                    </div>
                    <div class="row">
                        <button type="button" class="enviar" (click)="onPressSend()">Enviar</button>
                        <button type="button" class="fechar" (click)="cancelar()">Fechar</button>
                        <common-status-dialog #statusDialog [list]="limparForm"></common-status-dialog>
                        <common-confirm-dialog
                            #confirmCancelarDialog
                            [accept]="limparForm"
                            [data]="confirmCancelarDialogData"
                        ></common-confirm-dialog>

                        <app-gestor-vinculos-dialog
                            id="maxVinculosDialog"
                            #maxVinculosDialog
                            [accept]="showModalDialogConfirm"
                            [data]="maxVinculosDialogData"
                            [municipios]="municipiosVinculados"
                            [(vinculosRemover)]="vinculosRemover"
                        >
                        </app-gestor-vinculos-dialog>
                        <common-confirm-dialog
                            #confirmVinculosDialog
                            [accept]="onConfirmSend"
                            [data]="confirmVinculosDialogData">
                        </common-confirm-dialog>
                    </div>

                    <h3>Dados do Gestor</h3>
                    <hr />
                    <h3>
                        <strong>Atenção:</strong> Só é possível cadastrar um Gestor RBC/RS por município. A solicitação de cadastro de um novo Gestor,
                        substituirá o atual.
                    </h3>
                    <div class="form-row">
                        <div class="form-group col-lg-6">
                            <label for="inputEmail4">Nome*</label>
                            <span class="p-float-label">
                                <input
                                    type="text"
                                    formControlName="nome"
                                    id="nome"
                                    pInputText
                                    class="w-100 p-inputtext-sm"
                                    maxlength="100"
                                    (keypress)="alphaOnly($event)"
                                />
                                <ng-container *ngFor="let erro of mensagem.nome">
                                    <span class="msg-erro"> </span>
                                </ng-container>
                                <div class="text-right w-100">
                                    <small *ngIf="fieldMessageRequired('nome')" class="msg-field-required">Preenchimento obrigatório</small>
                                </div>
                            </span>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="inputEmail4">CPF*</label>
                            <input
                                type="text"
                                class="form-control"
                                id="inputCPF4"
                                formControlName="cpf"
                                mask="000.000.000-00"
                                maxlength="14"
                                pInputText
                            />
                            <!--<input data-inputmask="'mask': '000.000.000-00'"/>-->
                            <!--<input type="text" [specialCharacters]="[ '[' ,']' , '\\' ]" mask="[00]\[000]" />-->
                            <div class="text-right w-100">
                                <small *ngIf="fieldMessageRequired('cpf')" class="msg-field-required">Preenchimento obrigatório</small>
                            </div>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="inputEmail4">RG</label>
                            <input type="text" formControlName="rg" class="form-control" id="inputRG4" maxlength="12" />
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="inputCelular" class="label-class">Celular* (principal)</label>
                            <input
                                type="text"
                                formControlName="telefone"
                                class="form-control input-class"
                                id="inputCelular"
                                pInputText
                                mask="(00)00000-0000||(00)0000-0000"
                                maxlength="14"
                            />
                            <div class="text-right w-100">
                                <small *ngIf="fieldMessageRequired('telefone')" class="msg-field-required">Preenchimento obrigatório</small>
                            </div>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="inputTelefone4" class="label-class">Telefone (alternativo)</label>
                            <input
                                type="text"
                                formControlName="telefoneAlternativo"
                                class="form-control input-class"
                                id="inputTelefone4"
                                pInputText
                                mask="(00)00000-0000||(00)0000-0000"
                                maxlength="14"
                            />
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="inputEmail4">E-mail*</label>
                            <input
                                type="email"
                                formControlName="email"
                                class="form-control"
                                id="email"
                                pInputText
                                maxlength="64"
                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                            />
                            <div class="text-right w-100">
                                <small *ngIf="fieldMessageRequired('email')" class="msg-field-required">Preenchimento obrigatório</small>
                            </div>
                        </div>

                        <div class="form-group col-md-4">
                          <label for="inputIne">INE*</label>
                          <input
                              type="number"
                              formControlName="ine"
                              class="form-control"
                              id="inputIne"
                              pInputText
                              min="0"
                              max="9999999999"
                          />
                          <div class="text-right w-100">
                              <small *ngIf="fieldMessageRequired('ine')" class="msg-field-required">Preenchimento obrigatório</small>
                          </div>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="inputEmail4">Formação*</label>
                            <input
                                type="formacao"
                                formControlName="formacao"
                                class="form-control"
                                id="inputFormacao4"
                                pInputText
                                maxlength="70"
                                (keypress)="alphaOnly($event)"
                            />
                            <div class="text-right w-100">
                                <small *ngIf="fieldMessageRequired('formacao')" class="msg-field-required">Preenchimento obrigatório</small>
                            </div>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="inputEmail4">Cargo*</label>
                            <input
                                type="cargo"
                                formControlName="cargo"
                                class="form-control"
                                id="inputCargo4"
                                pInputText
                                maxlength="70"
                                (keypress)="alphaOnly($event)"
                            />
                            <div class="text-right w-100">
                                <small *ngIf="fieldMessageRequired('cargo')" class="msg-field-required">Preenchimento obrigatório</small>
                            </div>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="inputEmail4">Município*</label>
                            <select id="inputMunicipio" class="form-control" formControlName="municipio">
                                <option value="" selected>Escolher...</option>
                                <option *ngFor="let municipio of municipios" [ngValue]="municipio">{{ municipio.nome }}</option>
                            </select>
                            <div class="text-right w-100">
                                <small *ngIf="fieldMessageRequired('municipio')" class="msg-field-required">Preenchimento obrigatório</small>
                            </div>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="disableTextInput">CRS*</label>
                            <input type="text" class="form-control" id="disableTextInput" readonly formControlName="nomeCrs" />
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="label-file">Adicionar arquivo*</label>
                    <label class="pointer btn-file" for="formFile">Adicionar arquivo</label>
                    <input
                        type="file"
                        class="form-control-file"
                        id="formFile"
                        required
                        accept="application/pdf"
                        (change)="uploadArquivo($event.target.files)"
                    />
                    <a class="ml-3 file-name" *ngIf="arquivo" (click)="downloadArquivo()">
                        {{ arquivo?.nome }}
                    </a>
                    <em *ngIf="arquivo" (click)="excluirArquivo()" class="ml-2 fa fa-times" aria-hidden="true"> </em>

                    <div class="text-left w-100 label-file">
                        <small *ngIf="!flagErroArquivo">Formato de arquivo permitido: PDF</small>
                    </div>

                    <div class="text-left w-100 label-file">
                        <small *ngIf="fieldMessageRequired('idDocumentoAlfresco') || flagErroArquivo" class="msg-field-required">{{
                            msgErroArquivo
                        }}</small>
                    </div>
                </div>

                <h3>Dados do Secretário Municipal</h3>
                <hr />

                <div class="form-row">
                  <div class="form-group col-lg-6">
                    <label for="nomeSecretario1" class="label-class">Nome*</label>
                    <span class="p-float-label">
                        <input
                            type="text"
                            formControlName="nomeSecretario"
                            id="nomeSecretario1"
                            pInputText
                            class="w-100 p-inputtext-sm"
                            maxlength="100"
                            (keypress)="alphaOnly($event)"
                        />
                        <div class="text-right w-100">
                            <small *ngIf="fieldMessageRequired('nomeSecretario')" class="msg-field-required">Preenchimento obrigatório</small>
                        </div>
                    </span>
                  </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-4">
                  <label for="telefoneSecretario1" class="label-class">Telefone*</label>
                  <input
                      type="text"
                      formControlName="telefoneSecretario"
                      id="telefoneSecretario1"
                      pInputText
                      class="form-control"
                      maxlength="14"
                      mask="(00)00000-0000||(00)0000-0000"
                  />
                  <div class="text-right w-100">
                      <small *ngIf="fieldMessageRequired('telefoneSecretario')" class="msg-field-required">Preenchimento obrigatório</small>
                  </div>
                </div>

              </div>


              <div class="form-row">
                  <div class="form-group col-md-4">
                      <label for="emailSecretario1">E-mail*</label>
                      <input
                          type="email"
                          formControlName="emailSecretario"
                          class="form-control"
                          id="emailSecretario1"
                          pInputText
                          maxlength="64"
                          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                      />
                      <div class="text-right w-100">
                          <small *ngIf="fieldMessageRequired('emailSecretario')" class="msg-field-required">Preenchimento obrigatório</small>
                      </div>
                  </div>
              </div>

            </form>
        </div>

        <common-status-dialog #fileRejected></common-status-dialog>

        <p-dialog
            [(visible)]="displayModal"
            [modal]="true"
            [style]="{ width: '50vw' }"
            [baseZIndex]="10000"
            [draggable]="false"
            [resizable]="false"
            [closable]="false"
        >
            <div class="col-12 text-center text-bold">Confirma o envio dos dados?</div>
            <ng-template pTemplate="footer">
                <button type="button" pButton icon="pi pi-check" class="p-button-success" label="Sim" (click)="onConfirmSend()"></button>
                <button type="button" pButton icon="pi pi-times" class="p-button-danger" label="Não" (click)="displayModal = false"></button>
            </ng-template>
        </p-dialog>

        <p-dialog
            [(visible)]="displayModalAnexoOk"
            [modal]="true"
            [style]="{ width: '50vw' }"
            [baseZIndex]="10000"
            [draggable]="false"
            [resizable]="false"
            [closable]="false"
        >
            <div class="col-12 text-center text-bold">Documento anexado com sucesso</div>
            <ng-template pTemplate="footer">
                <button type="button" pButton icon="pi pi-check" class="p-button-success" label="Ok" (click)="displayModalAnexoOk = false"></button>
            </ng-template>
        </p-dialog>

        <p-dialog
            [(visible)]="displayModalCpfCadastradoMunicipio"
            [modal]="true"
            [style]="{ width: '50vw' }"
            [baseZIndex]="10000"
            [draggable]="false"
            [resizable]="false"
            [closable]="false"
        >
            <div class="col-12 text-center text-bold">O CPF informado já está cadastrado para este município.</div>
            <div class="col-12 text-center text-bold">Caso tenha esquecido a senha, clique em "Entrar no Sistema / Dificuldades no acesso?" ou entre em contato com o administrador do SISRBC.</div>
            <ng-template pTemplate="footer">
                <button type="button" pButton icon="pi pi-check" class="p-button-success" label="Ok" (click)="displayModalCpfCadastradoMunicipio = false"></button>
            </ng-template>
        </p-dialog>

        <p-progressSpinner
            *ngIf="carregando"
            [style]="{ width: '50px', height: '50px' }"
            styleClass="custom-spinner"
            strokeWidth="8"
            fill="var(--surface-ground)"
            animationDuration=".8s"
        ></p-progressSpinner>
    </div>
</common-page>
