export class ConfirmDialogData {
    constructor(
    public title: string,
    public message: string,
    public labelBtnAccept: string = 'Sim',
    public labelBtnReject: string = 'Não',
    public width: string = '50vw'
    ) {}

    public static ofMsg = (msg: string) => new ConfirmDialogData(null, msg);

    public tit(tit: string): ConfirmDialogData {
        this.title = tit;
        return this;
    }

    public msg(msg: string): ConfirmDialogData {
        this.message = msg;
        return this;
    }

    public wdt(wdt: string): ConfirmDialogData {
        this.width = wdt;
        return this;
    }

    public static builder(msg: string, title = null): ConfirmDialogData {
        return new ConfirmDialogData(title, msg);
    }


}
