<div class="login">
    &nbsp;
    <div class="loginColumns animated fadeInRight gray-bg b-r-lg">
        <div class="text-center">
            <div class="center-orientation">
                <img class="apm-logo" src="assets/images/logo-rbc.png" />
            </div>
        </div>
        <button type="submit" class="btn btn-dark btn-success full-width mt-2" (click)="login()">Entrar no Sistema</button>
        <button type="submit" class="btn btn-dark btn-success full-width mt-2" (click)="novoGestor()">Cadastrar Gestor</button>

        <div class="text-right mt-3">
            <img src="assets/images/logo_procergs.gif" />&nbsp;
            <small>&copy; 2022</small>
        </div>
    </div>
</div>
