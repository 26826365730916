import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { Page } from 'src/app/shared/models/page.model'
import { BaseService } from '../../base/services/base.service'
import { ObjUtil } from '../../base/util/obj.util'
import { CodigoDescricaoDTO } from '../dto/codigo-descricao'
import { UsuarioGestorDTO } from '../dto/usuario-gestor.dto'
import { Gestor } from '../models/gestor.model'
import { environment } from 'src/environments/environment'

@Injectable({
    providedIn: 'root',
})
export class GestorService extends BaseService<UsuarioGestorDTO> {
    constructor(public http: HttpClient) {
        super(http)
    }

    validarSigla(sigla: string): Observable<any> {
        return this.http.get<Gestor>(this.baseUrl + 'exists', { headers: this.headers, params: { sigla: sigla } })
    }

    public getPathModule(): string {
        return 'usuario-gestor/'
    }

    public isGestorMunicipio(idUsuario: number, idMunicipio: number): Observable<any> {
        let url = this.baseUrl
        url = url.concat('possui-gestor-ativo')
        url = url.concat('/' + idUsuario)
        url = url.concat('/' + idMunicipio)
        return this.http.get<any>(url, { headers: this.headers })
    }

    public validarGestor(obj: UsuarioGestorDTO, id: number): Observable<any> {
        const url = `${this.baseUrl}valida/${id}`
        return this.http.put<any>(url, obj, { headers: this.headers })
    }

    public municipiosVinculados(cpf:string): Observable<CodigoDescricaoDTO[]> {
        return this.http.get<CodigoDescricaoDTO[]>(`${this.baseUrl}municipios-usuario/${cpf}`, { headers: this.headers });
    }

    public getList(): Observable<any[]> {
        return this.http.get<Gestor[]>(this.baseUrl + 'listarGestoresAtivos', { headers: this.headers })
    }

    public getPagedList(page: number = 0, size: number = 20, params: Map<string, any> = null): Observable<Page<UsuarioGestorDTO>> | any {
        return this.http.get<Page<UsuarioGestorDTO>>(this.baseUrl, { headers: this.headers, params: this.getPagedListParams(page, size, params) })
    }

    exportar(params: Map<string, any>): Observable<Blob> {
        const httpHeader = new HttpHeaders({ Accept: 'application/octet-stream' })
        return this.http.get(this.baseUrl + 'exportar', { headers: httpHeader, params: this.getExportarParams(params), responseType: 'blob' })
    }
    public getExportarParams(paramsMap: Map<string, any>) {
        if (ObjUtil.isNotNull(paramsMap)) {
            const params = {}
            paramsMap.forEach((v, k) => {
                if (!ObjUtil.isEmpty(v)) {
                    params[k] = v
                }
            })
            return params
        }
    }

    public consultaEquipesPorGestor(id: number): Observable<any> {
        const url = `${this.baseUrl}equipes/${id}`
        return this.http.get<any>(url, { headers: this.headers })
    }

    public estenderPrazoOperacao(codUsuarioSoe: number): Observable<any> {
        const url = `${this.baseUrl}estender-prazo-operacao/${codUsuarioSoe}`
        return this.http.post<any>(url, { headers: this.headers })
    }

    public inicializarSenha(codUsuarioSoe: number): Observable<any> {
        const url = `${this.baseUrl}inicializar-senha/${codUsuarioSoe}`
        return this.http.post<any>(url, { headers: this.headers })
    }

    public salvarEquipes(equipes: any): Observable<any> {
        const url = `${environment.serverUrl}equipe/editar-esf`
        return this.http.put<any>(url, equipes, { headers: this.headers })
    }

}
