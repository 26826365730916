import { BtnAtivoInativoIconHint } from 'src/app/shared/models/btn-ativo-inativo-icon-hint.model'
import { BtnIconHint } from 'src/app/shared/models/btn-icon-hint.model'
import { ConfirmDialogData } from 'src/app/shared/models/confirm-dialog-data.model'

export class GestorConstant {
    private constructor() {}

    public static TITLE_PROGRAMAS_FOMENTO = 'Gestor';
    public static ATIVO_INATIVO_HINT = new BtnAtivoInativoIconHint('Gestor Ativo, clique para Inativar.', '', 'Gestor Inativo. Clique para Ativar.');
    public static EDIT_PROGRAMA_FOMENTO_BTN_ICON_HINT = new BtnIconHint(
        'pi icon-edit-custom',
        'Para editar o gestor clique aqui',
        'pi icon-edit-custom-disabled',
        'Não é permitida a edição de um gestor inativo. Reative o gestor para editar o registro.'
    );

    public static SUBTITLE_NEW_GESTOR = '';
    public static TITLE_CADASTRO_GESTOR = '';

    public static SUBTITLE_EDIT_GESTOR = '';

    public static CONFIRM_DIALOG_CANCELAR_GESTOR: ConfirmDialogData = new ConfirmDialogData(null, 'As alterações serão perdidas. Deseja continuar?');
    public static CONFIRM_DIALOG_EDITAR_GESTOR: ConfirmDialogData = new ConfirmDialogData(
        null,
        'Já existe um Gestor ativo para o município. Ao continuar o Gestor terá o seu usuário desativado. Deseja continuar?'
    );
    public static CONFIRM_DIALOG_ENVIO_GESTOR: ConfirmDialogData = new ConfirmDialogData(null, 'Confirma o envio dos dados?');

    public static TITLE_LISTA_GESTOR = 'Gestor - Listar';

    public static TITLE_EDITAR_GESTOR = 'Alterar cadastro do Gestor RBC do Município';

    public static CONFIRM_DIALOG_VINCULOS_GESTOR: ConfirmDialogData = new ConfirmDialogData(null, 'Você já está vinculado a outros municipios. Deseja continuar?');
    public static MAX_MUNICIPIOS_VINCULADOS: number = 5;
}
