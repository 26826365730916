import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { BaseService } from "../../base/services/base.service";
import { Municipio } from "../models/municipio.model";

@Injectable({
    providedIn: 'root'
})

export class MunicipiosService extends BaseService<Municipio> {
  
  
    constructor(public http: HttpClient) {
        super(http)
    }

    municipiosList() {
        return this.http.get<any[]>(environment.serverUrl + 'municipio/lista-municipios-setor');
    }

    todosMunicipiosList() {
        return this.http.get<any[]>(environment.serverUrl + 'municipio/lista-municipios-sem-autenticacao');
    }

    getMunicipios() {
        return this.http.get<any[]>(environment.serverUrl + 'municipio');
    }

    getMunicipioByCrsId(crsId: number) {
        return this.http.get<any[]>(environment.serverUrl + 'municipio/crs/'+crsId);
    }

    public getPathModule(): string {
        return 'municipio/'
    }
}
