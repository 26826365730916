import { ActivatedRoute, Router } from '@angular/router';
import { CrudEnum } from 'src/app/modules/base/enum/crud.enum';

import { BaseRoute } from '../models/base-route.model';
import { BaseService } from '../services/base.service';
import { ObjUtil } from '../util/obj.util';
import { BaseComponent } from "./base.component";

export abstract class BaseDetailComponent<T, S extends BaseService<T>> extends BaseComponent {

    _entity: T;
    _operacao: CrudEnum;

    constructor(public router: Router, public activatedRoute: ActivatedRoute, public baseUrl: BaseRoute, public service: S) {
        super(router);
        this._operacao = this.activatedRoute.snapshot.data['operacao'];
        if(ObjUtil.isEmpty(this._operacao)) {
            this._operacao = this.activatedRoute.parent.snapshot.data['operacao'];
            if(ObjUtil.isEmpty(this._operacao)) {
                this._operacao = this.activatedRoute.snapshot.firstChild?.data['operacao'];
            }
        }

        this._entity = this.activatedRoute.snapshot.data['entity'];
        if(ObjUtil.isEmpty(this._entity)) {
            this._entity = this.activatedRoute.parent.snapshot.data['entity'];
            if(ObjUtil.isEmpty(this._entity)) {
                this._entity = this.activatedRoute.snapshot.firstChild?.data['entity'];
            }
        }
    }

    /**
     * Navegação para rota padrão de listagem de registros.
     */
    goToList = (): void => {
        this.router.navigate([this.baseUrl.url, 'list']);
    }

    /**
   * Método responsável por carregar a última alteração do usuário.
   */
    public loadLastUpdateUser(): void {
        this.addSub(
            this.service.getLastUpdateUser().subscribe(res => {
                this.lastUpdateUser = res;
            })
        );
    }

    /**
   * #######################
   * ## Getters e Setters ##
   * #######################
   */

    get entity(): T {
        return this._entity;
    }

    get operacao(): CrudEnum {
        return this._operacao;
    }

}
