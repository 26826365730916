import { ThisReceiver } from '@angular/compiler'
import { GestorConstant } from 'src/app/modules/gestores/gestor/constants/gestor.constant'
import { MAX_ALFRESCO_FILENAME, MAX_ALFRESCO_FILESIZE_MB } from 'src/app/shared/services/alfresco.service'

export class StatusDialog {
    constructor(public iconStatus: string, public statusMessage: string) {}

    public static ofSuccess = (message: string) => new StatusDialog('icon-success-custom', message)
    public static ofError = (message: string) => new StatusDialog('icon-error-custom', message)
    public static ofWarn = (message: string) => new StatusDialog('icon-warn-custom', message)
    public static SALVO = this.ofSuccess('Informações enviadas com sucesso. Aguarde validação da equipe RBC/RS.')
    public static CRITERIO_CADASTRADO = this.ofSuccess('Novo critério de monitoramento salvo com sucesso!')
    public static CRITERIO_ALTERADO = this.ofSuccess('Alteração salva com sucesso!')
    public static EDITADO = this.ofSuccess('Dados alterados com sucesso.')
    public static SUCESSO = this.ofSuccess('Operação realizada com sucesso!')
    public static ERRO_SALVAR = this.ofError('Erro ao salvar o documento. Por favor tente novamente.')
    public static FECHAR = this.ofError('Deseja realmente fechar? Seus dados de cadastro serão perdidos.S')
    public static ERRO_CAMPOS_OBRIGATORIOS = this.ofError('Verifique e preencha os campos obrigatórios.')

    public static CRITM_VALID_CAMPO_JUSTIFICATIVA = this.ofWarn('Informe uma justificativa para a alteração.')
    public static CRITM_VALID_CAMPOS = this.ofWarn('Informe uma justificativa para a alteração.')
    public static NENHUMA_ALTERACAO = this.ofWarn('Nenhuma alteração realizada.')

    public static ARQUIVO_NOME_TAMANHO_INVALIDO = this.ofWarn(
        `Nome do arquivo excede tamanho máximo de ${MAX_ALFRESCO_FILENAME} caracteres. <br>Tente renomear o arquivo.`
    )
    public static ARQUIVO_TAMANHO_INVALIDO = this.ofWarn(`Arquivo excede tamanho máximo de ${MAX_ALFRESCO_FILESIZE_MB}MB.`)

    public static CICLO_ALTERAR_PROIBIDO = this.ofWarn('O Ciclo de Monitoramento não pode ser alterado.')
    public static CICLO_ATIVAR_PROIBIDO = this.ofError(
        `Já existe um Ciclo de Monitoramento ativo. 
        <br>Para prosseguir desative o ciclo anterior antes de ativar um novo ciclo.`
    )
    public static CRITERIO_ALTERAR_PROIBIDO = this.ofWarn('O Critério de Monitoramento não pode ser alterado.')

    public static EMAIL_CADASTRADO = this.ofSuccess('Modelo de e-mail salvo com sucesso.')

    public static CADASTRO_VINCULO_MAXIMO_PROIBIDO = this.ofError(
        `Este gestor já esta vinculado a ${GestorConstant.MAX_MUNICIPIOS_VINCULADOS} municípios.
        <br>Para prosseguir é necessário que seja desfeito o vínculo com no mínimo 1 município.<br>Entre em contato com o gestor para verificar.`
    );


    public static COMPROVACAO_CADASTRADA = this.ofSuccess('Comprovação salva com sucesso.');

}
