<div class="template">
    <div class="top flex grid flex-column">
        <p-toolbar class="col-12 top-toolbar pl-0 pr-0">
            <div class="p-toolbar-group-left">
                <p-image src="assets/images/logo_rbc.png" alt="Logo RS" height="60" (click)="goToHome()"></p-image>
            </div>
            <div class="p-toolbar-group-right">
                <div class="user-info m-1 flex grid align-content-center justify-content-center align-items-center">
                    <div class="col" *ngIf="municipio">
                        <span class="municipio-selected">Município: {{municipio?.descricao}}</span>
                    </div>
                    <p-image src="assets/images/user-not-found.png" alt="Logo User" height="50"></p-image>
                    <div class="grid flex-column m-2">
                        <!--      <span class="name-user"> {{nomeUsuario ? nomeUsuario : '' }}</span>
            <span class="departamento">Perfil do usuário</span> -->

                        <span class="dropdown" dropdown>
                            <span class="name-user">
                                <a href dropdownToggle (click)="(false)" title="Usu&#xE1;rio conectado">
                                    <i class="fa fa-user iconUser"></i> {{ nomeUsuario }}
                                </a></span
                            >
                            <ul *dropdownMenu class="dropdown-menu animated fadeInRight" role="menu">
                                <li>
                                    <a [href]="urlSoeProfileString" target="_blank" class="dropdown-item">
                                        <i class="fa fa-key iconUser"></i> Perfil / Alterar senha</a
                                    >
                                </li>
                                <li *ngIf="isGestorExterno">
                                    <a class="dropdown-item" routerLink="municipio-select">
                                        <i class="fa fa-refresh iconUser"></i>
                                        Alterar município
                                    </a>
                                </li>
                            </ul>
                        </span>
                    </div>
                    <a class="menu-logout" (click)="logout()">
                        <i class="pi pi-sign-out iconExit"></i>
                        <span>Sair</span>
                    </a>
                </div>
            </div>
        </p-toolbar>
        <div class="flex grid menu-horizontal">
            <p-menubar [model]="menus"></p-menubar>
        </div>
    </div>
    <section class="main-body">
        <router-outlet></router-outlet>
    </section>
    <footer class="flex grid align-items-center align-content-center justify-content-end pr-3">
        <p-image src="assets/images/logo_procergs.gif" alt="Logo RS" width="70"></p-image>
    </footer>

    <p-dialog
        [(visible)]="showModalProfile"
        [modal]="true"
        [style]="{ width: '50vw' }"
        [baseZIndex]="10000"
        [draggable]="false"
        [resizable]="false"
        [closable]="true"
    >
        <div class="embed-responsive embed-responsive-4by3">
            <iframe class="embed-responsive-item" [src]="urlSoeProfile" allowfullscreen></iframe>
        </div>
    </p-dialog>
</div>
