import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { TemplateComponent } from './modules/core/template/template.component'
import { UnauthorizedComponent } from './modules/core/unauthorized/unauthorized.component'
import { HomeComponent } from './modules/core/home/home.component'
import { AuthGuardService } from './shared/services/auth-guard.service'
import { GestorFormComponent } from './modules/gestores/gestor/pages/gestor-form/gestor-form.component'
import { VinculosGestorGuardService } from './modules/gestor/services/vinculos-gestor-guard.service'
import { VinculoSelectComponent } from './modules/gestor/components/vinculo-select/vinculo-select.component'
import { ExternoGuardService } from './shared/services/externo-guard.service'

const routes: Routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'unauthorized', component: UnauthorizedComponent },
    { path: 'gestores-form', component: GestorFormComponent },
    { path: 'municipio-select', component: VinculoSelectComponent, canActivate: [ExternoGuardService] },
    {
        path: '',
        canActivate: [AuthGuardService, VinculosGestorGuardService],
        component: TemplateComponent,
        children: [
            { path: 'home', component: HomeComponent, canActivate: [AuthGuardService] },
            {
                path: 'gestores',
                loadChildren: () => import('./modules/gestores/gestores.module').then((m) => m.GestoresModule),
            },
            {
                path: 'criteriosMonitoramento',
                loadChildren: () =>
                    import('./modules/criterio-monitoramento/criterio-monitoramento.module').then((m) => m.CriterioMonitoramentoModule),
            },
            {
                path: 'ciclo',
                loadChildren: () => import('./modules/ciclo/ciclo.module').then((m) => m.CicloModule),
            },
            {
                path: 'ciclo-adesao',
                loadChildren: () => import('./modules/ciclo-adesao/ciclo-adesao.module').then((m) => m.CicloAdesaoModule),
            },
            {
                path: 'ciclo-monitoramento',
                loadChildren: () => import('./modules/ciclo-monitoramento/ciclo-monitoramento.module').then((m) => m.CicloMonitoramentoModule),
            },
            {
                path: 'gestor',
                loadChildren: () => import('./modules/gestor/gestor.module').then((m) => m.GestorModule),
            },
            {
                path: 'email',
                loadChildren: () => import('./modules/email/email.module').then((m) => m.EmailModule),
            },
            {
                path: 'comprovacao',
                loadChildren: () => import('./modules/comprovacao/comprovacao.module').then((m) => m.ComprovacaoModule),
            },
            {
                path: 'empenho',
                loadChildren: () => import('./modules/empenho/empenho.module').then((m) => m.EmpenhoModule),
            },
            {
                path: 'relatorio',
                loadChildren: () => import('./modules/relatorio-respostas/relatorio-respostas.module').then((m) => m.RelatorioRespostasModule),
            },
            {
                path: 'relatorio-emails',
                loadChildren: () => import('./modules/relatorio-emails/relatorio-emails.module').then((m) => m.RelatorioEmailsModule),
            },
            {
                path: 'equipe',
                loadChildren: () => import('./modules/equipe/equipe.module').then((m) => m.EquipeModule),
            },
        ],
    },
]

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
