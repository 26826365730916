import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { ImageModule } from 'primeng/image';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { PaginatorModule } from 'primeng/paginator';
import { PickListModule } from 'primeng/picklist';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { ToolbarModule } from 'primeng/toolbar';
import { BtnIconHintComponent } from './components/btn-icon-hint/btn-icon-hint.component';
import { BtnAtivoInativoComponent } from './components/btn-ativo-inativo/btn-ativo-inativo.component';
import { CommonAtivoInativoDialogComponent } from './components/common-ativo-inativo-dialog/common-ativo-inativo-dialog.component';
import { CommonConfirmDialogComponent } from './components/common-confirm-dialog/common-confirm-dialog.component';
import { CommonPageComponent } from './components/common-page/common-page.component';
import { CommonExclusaoDialogComponent } from './components/common-exclusao-dialog/common-exclusao-dialog.component';
import { PaginadorCustomComponent } from './components/paginador-custom/paginador-custom.component';
import { LastUpdateUserComponent } from './components/last-update-user/last-update-user.component';
import { CommonStatusDialogComponent } from './components/common-status-dialog/common-status-dialog.component';
import { FormFieldErrorComponent } from './components/form-field-error/form-field-error.component';
import { SituacaoComponent } from './components/situacao/situacao.component';
import { BrMaskerModule } from 'br-mask';
import { ComprovacoesAdesaoItensFormComponent } from './components/comprovacoes-adesao-itens-form/comprovacoes-adesao-itens-form.component'

@NgModule({
    declarations: [
        BtnIconHintComponent,
        BtnAtivoInativoComponent,
        CommonAtivoInativoDialogComponent,
        CommonConfirmDialogComponent,
        CommonExclusaoDialogComponent,
        CommonPageComponent,
        PaginadorCustomComponent,
        LastUpdateUserComponent,
        CommonStatusDialogComponent,
        FormFieldErrorComponent,
        SituacaoComponent,
        ComprovacoesAdesaoItensFormComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ButtonModule,
        CardModule,
        ConfirmDialogModule,
        DialogModule,
        ImageModule,
        InputTextModule,
        InputTextareaModule,
        PaginatorModule,
        ProgressSpinnerModule,
        PickListModule,
        RatingModule,
        RadioButtonModule,
        ScrollPanelModule,
        TableModule,
        ToolbarModule,
        TooltipModule,
        RouterModule,
        RouterModule,
        RadioButtonModule,
        BrMaskerModule,
    ],
    exports: [
        BtnIconHintComponent,
        BtnAtivoInativoComponent,
        CommonAtivoInativoDialogComponent,
        CommonConfirmDialogComponent,
        CommonExclusaoDialogComponent,
        CommonPageComponent,
        CommonStatusDialogComponent,
        PaginadorCustomComponent,
        LastUpdateUserComponent,
        FormFieldErrorComponent,
        SituacaoComponent,
        ComprovacoesAdesaoItensFormComponent,
        FormsModule,
        ReactiveFormsModule,
        ButtonModule,
        CardModule,
        ConfirmDialogModule,
        DialogModule,
        ImageModule,
        InputTextModule,
        InputTextareaModule,
        PaginatorModule,
        ProgressSpinnerModule,
        PickListModule,
        RadioButtonModule,
        ScrollPanelModule,
        TableModule,
        TooltipModule,
        ToolbarModule,
        RatingModule,
        RouterModule,
        RouterModule,
        RadioButtonModule,
    ],
})
export class CommonComponentsSharedModule {}
