import { CodigoDescricaoDTO } from './codigo-descricao';

export class UsuarioGestorDTO {
    constructor(
        public id: number = null,
        public nome: string = null,
        public cpf: string = null,
        public rg: string = null,
        public telefone: number = null,
        public telefoneAlternativo: number = null,
        public email: string = null,
        public formacao: string = null,
        public cargo: string = null,
        public codUsuarioSoe: number = null,
        public idSituacao: number = null,
        public idMunicipio: number = null,
        public nomeSecretarioPendente: string = null,
        public telefoneSecretarioPendente: number = null,
        public emailSecretarioPendente: string = null,
        public motivoAlteracaoSituacao: string = null,
        public idDocumentoAlfresco: number = null,
        public listaMunicipiosParaDesvincular: number[] = null,
        public observacaoDesvincularMunicipios: string = null,
        public municipiosDesvincular: CodigoDescricaoDTO[] = undefined,
        public municipiosVinculados: CodigoDescricaoDTO[] = undefined,
        public dthUltSessaoSoeFormatado: string = null,
        public dtPrazoOperacaoSoeFormatado: string = null,
        public emailsSoeFormatado: string = null,
        public ine: number = null,
    ) {}
}
