<p-dialog key [visible]="display" [style]="{ width: '50vw' }" [closeOnEscape]="false" [closable]="false" [modal]="true">
    <div class="grid align-itens-center" *ngIf="status">
        <div class="col-12 text-center"><i class="pi {{ status.iconStatus }}" style="font-size: 2rem"></i></div>
        <div class="col-12 text-center text-bold"><span [innerHtml]="status.statusMessage"></span></div>
    </div>
    <div class="grid align-itens-center mt-1" *ngIf="data">
        <div class="col-12 text-center text-bold"><span [innerHtml]="this.data.message"></span></div>
        <div class="col-12 text-left text-bold pb-0"> <h3>Indique qual município você não será mais Gestor RBC*</h3></div>
        <form [formGroup]="form" class="grid">
            <div class="col-12 pb-0">
                <div class="grid align-itens-center">
                    <div class="col-6" *ngFor="let municipio of municipios; let i=index">
                        <input type="checkbox" [value]="municipio.codigo" (change)="onSelectMunicipio($event.target.value, $event.target.checked)"/> {{municipio.descricao}}
                    </div>
                </div>
            </div>
            <div class="col-12 text-left w-100 pt-0 pb-0">
                <small *ngIf="isMunicipiosInvalid()" class="msg-field-required">Selecione pelo menos um município</small>
            </div>
            <div class="col-12">
                <div class="text-bold">Observação</div>
                <textarea
                    class="w-full form-control"
                    [formControlName]="FORM_CONTROLS.OBSERVACAO"
                    maxlength="4000"
                ></textarea>
            </div>
        </form>
    </div>
    <ng-template pTemplate="footer">
        <button
            [disabled]="!this.form.valid"
            type="button"
            pButton
            icon="pi pi-check"
            class="p-button-success"
            label="{{ this.data.labelBtnAccept }}"
            (click)="acceptFunction()"
        ></button>
        <button
            type="button"
            pButton
            icon="pi pi-times"
            class="p-button-danger"
            label="{{ this.data.labelBtnReject }}"
            (click)="rejectFunction()"
        ></button>
    </ng-template>
</p-dialog>
