import { MenuItem } from 'primeng/api'
import { AuthService, getClientSettings } from 'src/app/shared/services/auth.service'
import { Router } from '@angular/router'
import { Component, OnInit } from '@angular/core'
import { UserManager } from 'oidc-client'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'
import { environment } from 'src/environments/environment'
import { VinculosGestorService } from '../../gestor/services/vinculos-gestor.service'
import { CodigoDescricaoDTO } from '../../gestor/model/codigo-descricao-dto.model'
import { CrsService } from '../../gestores/services/crs.service'

@Component({
    selector: 'app-template',
    templateUrl: './template.component.html',
    styleUrls: ['./template.component.css'],
})
export class TemplateComponent implements OnInit {
    mgr: UserManager = new UserManager(getClientSettings())
    nomeUsuario: string
    _isAuthenticated = false
    menus: MenuItem[]
    tipoUser: ''
    urlSoeProfile: SafeResourceUrl
    urlSoeProfileString: string
    showModalProfile: boolean

    isGestorExterno: boolean
    municipio: CodigoDescricaoDTO

    menuAdmin = [
        { label: 'Home', routerLink: ['/home'], icon: 'pi pi-home' },
        {
            label: 'Administração',
            routerLinkActiveOptions: { matrixParams: 'subset' },
            items: [
                { label: 'Gestor', routerLink: ['/gestores/list'] },
                { label: 'Critério de adesão', routerLink: ['/comprovacao/list'] },
                { label: 'Ciclo de adesão', routerLink: ['/ciclo-adesao/list'] },
                { label: 'Ciclo de monitoramento', routerLink: ['/ciclo/ciclos'] },
                { label: 'Critério de monitoramento', routerLink: ['/criteriosMonitoramento/listar-criterios'] },
                { label: 'Modelo de e-mail', routerLink: ['/email/list'] },
                {
                    label: 'Relatório',
                    items: [
                        { label: 'Repostas', routerLink: ['/relatorio/respostas'] },
                        { label: 'Notificações por e-mail', routerLink: ['/relatorio-emails'] }
                    ],
                },
                {
                    label: 'Equipes',
                    items: [
                        { label: 'Importar', routerLink: ['/equipe/equipe-importar'] },
                        { label: 'Exportar', routerLink: ['/equipe/equipe-exportar'] }
                    ],
                },
            ],
        },
        {
            label: 'Adesão',
            routerLinkActiveOptions: { matrixParams: 'subset' },
            items: [{ label: 'Adesão', routerLink: ['/gestores/list-adesao'] }],
        },
        {
            label: 'Monitoramento',
            routerLinkActiveOptions: { matrixParams: 'subset' },
            items: [{ label: 'Lista de Ciclos', routerLink: ['/ciclo-monitoramento/listar'] }],
        },
        {
            label: 'Empenho',
            routerLinkActiveOptions: { matrixParams: 'subset' },
            items: [
                { label: 'Registro', routerLink: ['/gestores/form-registrar-empenho'] },
                { label: 'Histórico', routerLink: ['/empenho'] },
                { label: 'Cálculo', routerLink: ['/ciclo-monitoramento/listar-para-repasse'] },
            ],
        },
    ]

    menuGestor = [
        { label: 'Home', routerLink: ['/home'], icon: 'pi pi-home' },
        { label: 'Dados pessoais', routerLink: ['gestor/dadosGestor'], icon: 'pi pi-user' },
        { label: 'Adesão', routerLink: ['/gestor/adesaoForm'] },
        { label: 'Empenho', routerLink: ['/gestor/empenhoForm'] },
        { label: 'Monitoramento', routerLink: ['/gestor/monitoramentoForm'] },
    ]

    menuCRS = [
        { label: 'Home', routerLink: ['/home'], icon: 'pi pi-home' },
        {
            label: 'Administração',
            routerLinkActiveOptions: { matrixParams: 'subset' },
            items: [
                { label: 'Gestor', routerLink: ['/gestores/list'] },
                {
                    label: 'Relatório',
                    routerLink: [''],
                    items: [{ label: 'Repostas', routerLink: ['/relatorio/respostas'] }],
                },
            ],
        },
        {
            label: 'Adesão',
            routerLinkActiveOptions: { matrixParams: 'subset' },
            items: [{ label: 'Adesão', routerLink: ['/gestores/list-adesao'] }],
        },
        {
            label: 'Monitoramento',
            routerLinkActiveOptions: { matrixParams: 'subset' },
            items: [{ label: 'Lista de Ciclos', routerLink: ['/ciclo-monitoramento/listar'] }],
        },
        {
            label: 'Empenho',
            routerLinkActiveOptions: { matrixParams: 'subset' },
            items: [{ label: 'Histórico', routerLink: ['/empenho'] }],
        },
    ]

    constructor(
        public router: Router,
        private oidcAuthService: AuthService,
        private sanitizer: DomSanitizer,
        private vinculosService: VinculosGestorService,
        private crsService: CrsService
    ) {}

    ngOnInit(): void {
        this.oidcAuthService.isExternoUser().subscribe((v) => (this.isGestorExterno = v))
        this.municipio = this.vinculosService.getSelectedMunicipio()

        this.urlSoeProfile = this.sanitizer.bypassSecurityTrustResourceUrl(environment.soeProfile)
        this.urlSoeProfileString = environment.soeProfile

        this.oidcAuthService.getUserName().subscribe((res) => {
            this.nomeUsuario = res
        })

        // this.crsService.isUsuarioCRS().subscribe((resultado) => {
        //     if (resultado) {
        //         this.menus = this.menuCRS
        //     } else {
        //         if (localStorage.getItem('organizacao') == 'SES') {
        //             this.menus = this.menuAdmin
        //         } else {
        //             this.menus = this.menuGestor
        //         }
        //     }
        // })

        const isUsuarioCRS = sessionStorage.getItem('isUsuarioCRS')
        if( isUsuarioCRS == null ) {
            this.crsService.isUsuarioCRS().subscribe((resultado) => {
                sessionStorage.setItem('isUsuarioCRS', resultado.toString())
                this.setMenus(resultado)
            })
        }else{
            this.setMenus(isUsuarioCRS === 'true')
        }
    }

    setMenus(resultado: boolean) {
        if (resultado) {
            this.menus = this.menuCRS
        } else {
            if (localStorage.getItem('organizacao') == 'SES') {
                this.menus = this.menuAdmin
            } else {
                this.menus = this.menuGestor
            }
        }
    }

    get isAutenticated() {
        return this._isAuthenticated
    }

    goToHome() {
        this.router.navigate(['/'])
    }

    logout() {
        this.oidcAuthService.removeUser()
        this.vinculosService.selectMunicipio(null)
        this.router.navigate(['/unauthorized'])
        sessionStorage.setItem('isUsuarioCRS', null)
    }

    alterarSenhaDialog() {
        this.showModalProfile = true
    }
}
