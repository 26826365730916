import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmDialogData } from 'src/app/shared/models/confirm-dialog-data.model';
import { StatusDialog } from 'src/app/shared/models/status-dialog.model';
import { CodigoDescricaoDTO } from '../../../dto/codigo-descricao';

export class VinculosRemoverFormDTO {
    constructor(
    public municipios:number[],
    public observacao:string
    ) {}
}

@Component({
    selector: 'app-gestor-vinculos-dialog',
    templateUrl: './gestor-vinculos-dialog.component.html',
    styleUrls: ['./gestor-vinculos-dialog.component.css']
})
export class GestorVinculosDialogComponent implements OnInit {

  @Input() municipios: CodigoDescricaoDTO[] = []
  @Input() vinculosRemover: VinculosRemoverFormDTO;
  @Output() vinculosRemoverChange = new EventEmitter<VinculosRemoverFormDTO>();

  @Input() status: StatusDialog
  @Input() data: ConfirmDialogData = new ConfirmDialogData(`Você já está vinculado a ${this.municipios?.length} município(s), esse é o limite máximo`, 
      'Indique qual município você não será mais Gestor RBC*')
  @Input() accept: Function
  @Input() reject: Function = () => {}

  private _display: boolean = false

  form:FormGroup
  FORM_CONTROLS:{MUNICIPIOS:string, OBSERVACAO:string} = {
      MUNICIPIOS: 'municipios',
      OBSERVACAO: 'observacao'
  }

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
      this.initForm();
  }

  initForm(){
      this.form = this.fb.group({
          municipios: this.fb.array([], Validators.required),
          observacao: this.fb.control(null)
      })
  }

  isMunicipiosInvalid(): boolean {
      return (this.form.get(this.FORM_CONTROLS.MUNICIPIOS) as FormArray).length == 0;
  }

  onSelectMunicipio(idStr: string, checked: boolean) {
      const id:number = Number.parseInt(idStr, 10)
      const formMunicipios = this.form.get(this.FORM_CONTROLS.MUNICIPIOS) as FormArray
      if(checked) {
          formMunicipios.push(this.fb.control(id))
      } else {
          let removeIdx = -1
          let idx = 0
          formMunicipios.controls.forEach(c => {
              if(c.value == id) {removeIdx = idx;}
              idx++;
          })
          if(removeIdx != -1) {
              formMunicipios.removeAt(removeIdx);
          }
      }
  }

  clearForm(){
      this.form.reset()
      const formMunicipios = this.form.get(this.FORM_CONTROLS.MUNICIPIOS) as FormArray
      formMunicipios.clear();
  }

  openDialog() {
      this._display = true
  }

  createData(){
      this.vinculosRemover = new VinculosRemoverFormDTO(
          this.form.get(this.FORM_CONTROLS.MUNICIPIOS).value,
          this.form.get(this.FORM_CONTROLS.OBSERVACAO).value
      );
      this.vinculosRemoverChange.emit(this.vinculosRemover);
  }

  acceptFunction() {
      if(!this.form.valid){
          return
      }
      this.createData();
      this.clearForm();

      this.close()
      this.accept()
  }

  rejectFunction() {
      this.clearForm();

      this.close()
      this.reject()
  }

  get display() {
      return this._display
  }

  close() {
      this._display = false
  }
}
