import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Inject, Injectable, Injector } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {

    constructor(@Inject(Injector) private injector: Injector) { }

    private get _toastrService(): ToastrService {
        return this.injector.get(ToastrService);
    }

    handleError(error: HttpErrorResponse | any): void {
        if (error.status === 400) {
            error.error.forEach(err => {
                this._toastrService.warning(err.message, 'Mensagem de erro');
            });
        } else if (error.status === 401 || error.status === 403) {
            this._toastrService.error('Você não está autorizado a acessar este recurso.');
        } else {
            console.error('Backend returned:', error);
            this._toastrService.error(error.message, 'Ocorreu um Erro, ao tentar executar esta ação.');
        }
    }
}
