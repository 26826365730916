import { HttpParams } from '@angular/common/http'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { AtivoInativoDialogData } from 'src/app/shared/models/ativo-inativo-dialog-data.model'
import { LastUpdateUser } from 'src/app/shared/models/last-update-user.model'
import { Observable } from 'rxjs'
import { Page } from 'src/app/shared/models/page.model'
import { environment } from '../../../../environments/environment'
import { ObjUtil } from '../util/obj.util'

export abstract class BaseService<T> {
    constructor(public http: HttpClient) {}

    public abstract getPathModule(): string

    public getById(id: number): Observable<T> {
        return this.http.get<T>(this.baseUrl + id, { headers: this.headers })
    }

    public getList(): Observable<T[]> {
        return this.http.get<T[]>(this.baseUrl, { headers: this.headers })
    }

    public getPagedList(page: number = 0, size: number = 20, params: Map<string, any> = null): Observable<Page<T>> | any {
        return this.http.get<Page<T>>(this.baseUrl, { headers: this.headers, params: this.getPagedListParams(page, size, params) })
    }

    public getPagedListEquipes(page: number = 0, size: number = 20, params: Map<string, any> = null): Observable<Page<T>> | any {
        const novoBaseUrl = environment.serverUrl + 'equipe-importacao/listarTodos'
        return this.http.get<Page<T>>(novoBaseUrl, { headers: this.headers, params: this.getPagedListParams(page, size, params) })
    }

    public getPagedListHistoricoCriterio(page: number = 0, size: number = 20, params: Map<string, any> = null): Observable<Page<T>> | any {
        const url = this.baseUrl.replace('undefined', 'criterio-monitoramento/historico/') + params.get('id')

        //let novoBaseUrl = 'https://secweb.des.intra.rs.gov.br/sisrbc/rest/criterio-monitoramento/historico/' + params.get('id')
        return this.http.get<Page<T>>(url, { headers: this.headers, params: this.getPagedListParams(page, size, params) })
    }


    public getPagedListHistoricoComprovacao(page: number = 0, size: number = 10, params: Map<string, any> = null): Observable<Page<T>> | any {

         const url = this.baseUrl.replace('undefined', 'comprovacao/historico/') + params.get('id')
         return this.http.get<Page<T>>(url, { headers: this.headers, params: this.getPagedListParams(page, size, params) })
    }



    public getPagedListCiclosPorCriterio(page: number = 0, size: number = 20, params: Map<string, any> = null): Observable<Page<T>> | any {
        //let url = this.baseUrl.replace('undefined', 'criterio-monitoramento/historico/') + params.get('id')

        const novoBaseUrl = environment.serverUrl + 'ciclo-monitoramento'
        return this.http.get<Page<T>>(novoBaseUrl, { headers: this.headers, params: this.getPagedListParams(page, size, params) })
    }

    public create(obj: T): Observable<T> {
        return this.http.post<T>(this.baseUrl, obj, { headers: this.headers })
    }

    public update(obj: T, id: number): Observable<T> {
        return this.http.put<T>(this.baseUrl + id, obj, { headers: this.headers })
    }

    public delete(id: number): Observable<any> {
        return this.http.delete(this.baseUrl + id, { headers: this.headers })
    }
    public deleteAll(ids: number[]): Observable<any> {
        const params = '?ids=' + ids.join('&ids=')
        return this.http.delete(this.baseUrl + 'all' + params, { headers: this.headers })
    }

    public ativarInativar(obj: AtivoInativoDialogData, id: number): Observable<any> {
        return this.http.patch(this.baseUrl + id + '/ativar-inativar', obj, { headers: this.headers })
    }

    public getLastUpdateUser(): Observable<LastUpdateUser> {
        return this.http.get<LastUpdateUser>(this.baseUrl + 'last-update', { headers: this.headers })
    }

    public getPagedListParams(page: number, size: number, paramsMap: Map<string, any>) {
        if (ObjUtil.isNotNull(paramsMap)) {
            const params = { first: page * size, size: size }
            paramsMap.forEach((v, k) => {
                if (!ObjUtil.isEmpty(v)) {
                    params[k] = v
                }
            })
            return params
        } else {
            return { first: page * size, size: size }
        }
    }

    public get baseUrl(): string {
        return environment.serverUrl + this.getPathModule()
    }

    public get headers(): HttpHeaders {
        const headers = new HttpHeaders()
        headers.append('Content-Type', 'application/json')
        return headers
    }
}
