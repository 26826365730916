import { Router } from '@angular/router';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        private authService: AuthService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.authService.loggedIn) {
            if (new Date(this.authService.currentUser.expires_at*1000) >= new Date()) {
                const authRequest = req.clone({
                    setHeaders: {
                        'Authorization': this.authService.currentUser.token_type + ' ' + this.authService.currentUser.access_token
                    }
                });
                return next.handle(authRequest);
            } else {
                this.router.navigate(['unauthorized']);
            }
        } else {
            return next.handle(req);
        }
    }
}
