import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { CodigoDescricaoDTO } from "../model/codigo-descricao-dto.model";

@Injectable()
export class VinculosGestorService {

    private _selectedMunicipio:BehaviorSubject<CodigoDescricaoDTO>;
    private _SESSIONKEY = 'municipio';

    constructor() {
        const sessionMunicipio = JSON.parse(sessionStorage.getItem(this._SESSIONKEY))
        this._selectedMunicipio = new BehaviorSubject<CodigoDescricaoDTO>(sessionMunicipio)
    }

    selectMunicipio(municipio: CodigoDescricaoDTO) {
        if(!municipio) {
            sessionStorage.removeItem(this._SESSIONKEY)
        } else {
            sessionStorage.setItem(this._SESSIONKEY, JSON.stringify(municipio))
        }
      
        this._selectedMunicipio.next(municipio);
    }

    getSelectedMunicipioObs(): Observable<CodigoDescricaoDTO> {
        return this._selectedMunicipio.asObservable();
    }

    getSelectedMunicipio(): CodigoDescricaoDTO {
        return this._selectedMunicipio.getValue()
    }

    getSelectedMunicipioId(): number {
        return this.getSelectedMunicipio()?.codigo;
    }


}

