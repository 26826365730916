export class ObjUtil {
    private constructor() { }

    public static isNull(o: any): boolean {
        return o === null || o === undefined || o === 'null' || o === 'undefined';
    }

    public static isNotNull(o: any): boolean {
        return !this.isNull(o);
    }

    public static isEmpty(o: any): boolean {
        if (this.isNull(o)) {
            return true;
        } else {
            if (typeof o == 'string') {
                return o.toString().trim() === '';
            } else if (o instanceof Array) {
                return o.length === 0;
            } else if (o instanceof Map) {
                return o.size === 0;
            } else {
                return false;
            }
        }
    }
}
