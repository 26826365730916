import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LastUpdateUser } from 'src/app/shared/models/last-update-user.model';

/**
 * Classe responsável por conter os métodos padrões para um componente.
 *
 * Nesta classe temos disponíveis as seguintes funcionalidades:
 * @method goTo()
 * - Navegação via route
 *
 * @method loading()
 * - Informar inicio de carregamento.
 *
 * @method loaded()
 * - Informar carremento completo.
 *
 * @method addSub()
 * - Adicionar _Subscription_ a lista.
 *
 * @method unsubscribeList()
 * - Dispose de itens da lista de subscription.
 *
 * @date 21/12/2021
 * @author Jhonny Luiz Cabral
 */
export abstract class BaseComponent {

    private _sub: Subscription[] = [];
    private _loading: boolean = true;
    lastUpdateUser: LastUpdateUser;


    constructor(public router: Router) {}

    /**
   * Utilize este método para realizar uma navegação usando o _Router_ de acordo
   * com a url passada como parametro.
   *
   * @param url
   */
    goTo = (url: string) => {
        this.router.navigate([url]);
    }

    /**
   * Adicionar uma Subscription a lista.
   *
   * @param sub
   */
    addSub(sub: Subscription): void { this._sub.push(sub); }

    /**
   * Realizar unsubscribe/dispose nos itens adicionados anteriomente a lista.
   */
    unsubscribeList(): void { this._sub.forEach(s => s.unsubscribe()); }

    /**
   * Início do carregamento. Este método é utilizado para um controle único de loading.
   */
    loading(): void { this._loading = true; }


    /**
   * Final do carregamento. Este método é utilizado para um controle único de loading.
   */
    loaded(): void { this._loading = false; }

    /**
   * #######################
   * ## Getters e Setters ##
   * #######################
   */

    get isLoading() { return this._loading; }
}
