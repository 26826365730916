import { AtivoInativoEnum } from 'src/app/shared/enum/ativo-inativo.enum';
export class BtnAtivoInativoIconHint {
    constructor(
    public hintAtivo: string,
    public hintAtivoDisabled: string,
    public hintInativo: string
    ) {}

    public getHint(indicacaoAtivo: AtivoInativoEnum, disabled: boolean = false) {
        if (AtivoInativoEnum.A == indicacaoAtivo) {
            return disabled ? this.hintAtivoDisabled : this.hintAtivo;
        }
        return this.hintInativo;
    }
}
