import { Component, OnInit } from '@angular/core';
import { VinculosGestorService } from "src/app/modules/gestor/services/vinculos-gestor.service";
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/modules/base/components/base.component';
import { finalize } from 'rxjs/operators';
import { GestorService } from 'src/app/modules/gestores/services/gestor.service';
import { CodigoDescricaoDTO } from '../../model/codigo-descricao-dto.model';

@Component({
    selector: 'app-vinculo-select',
    templateUrl: './vinculo-select.component.html',
    styleUrls: ['./vinculo-select.component.css']
})
export class VinculoSelectComponent extends BaseComponent implements OnInit {

    selectedMunicipioId: number = null
    municipios: CodigoDescricaoDTO[] = []

    constructor(
    public router: Router,
    private vinculosService:VinculosGestorService,
    private gestorService:GestorService,
    ) {
        super(router)
    }

    ngOnInit(): void {
        const cpf = localStorage.getItem('matricula');
        this.loading()
        this.addSub(
            this.gestorService.municipiosVinculados(cpf)
                .pipe(finalize(() => this.loaded()))
                .subscribe(res => {
                    this.municipios = res
                    const m = this.vinculosService.getSelectedMunicipio()
                    this.selectedMunicipioId = m ? m.codigo : null
                })
        )
    }

    onOk(){
        const municipio = this.municipios.find(v => v?.codigo == this.selectedMunicipioId)
        if(!municipio) 
        {return;}
    
        this.loading()
        this.vinculosService.selectMunicipio(municipio);
        this.router.navigate([''])
            .then(b => this.loaded())
    }

    onSelectChange(value:CodigoDescricaoDTO){
    }

}
