import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { combineLatest, Observable, of, throwError } from "rxjs";
import { concatMap, delay, map, retryWhen, take, tap } from "rxjs/operators";
import { AuthService } from "src/app/shared/services/auth.service";
import { GestorService } from "../../gestores/services/gestor.service";
import { CodigoDescricaoDTO } from "../model/codigo-descricao-dto.model";
import { VinculosGestorService } from "./vinculos-gestor.service";

@Injectable()
export class VinculosGestorGuardService implements CanActivate {
    
    constructor(
        private router: Router,
        private authService:AuthService,
        private vinculosService:VinculosGestorService,
        private gestorService:GestorService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        const MAX_RETRIES = 2;
        let retries = 0;
        return this.isExterno()
            .pipe(concatMap((externo) => {
                if(externo) {
                    const vinculosMunicipios = this.getVinculosMunicipio().pipe(take(1))
                    const selectedMunicipio = this.vinculosService.getSelectedMunicipio()
                    return combineLatest([vinculosMunicipios, of(selectedMunicipio), of(externo)])
                } else {
                    const vinculosMunicipios = of([])
                    const selectedMunicipio = of(null)
                    return combineLatest([vinculosMunicipios, selectedMunicipio, of(externo)])
                }
            }), 
            // retry to get data on error
            retryWhen(error => error.pipe(
                delay(2000), 
                take(MAX_RETRIES),
                concatMap((error) =>{
                    if(++retries == MAX_RETRIES) 
                    {return throwError(error)}
                    else
                    {return of([])}
                }
                )
            )
            )
            )
            .pipe(map<[any[], CodigoDescricaoDTO, boolean], boolean>(([vinculosMunicipios, selectedMunicipio, externo]) => {
                if(!externo) {
                    this.vinculosService.selectMunicipio(null)
                    return true
                }

                // apenas se aplica a usuario externo
                const validSelectedMunicipio = this.isSelectedMunicipioValid(vinculosMunicipios, selectedMunicipio);
                const qtdVinculos = vinculosMunicipios.length
                if(qtdVinculos == 0) { // nenhum vinculo ativo
                    this.router.navigate(['unauthorized']);
                } else if(qtdVinculos == 1) { // apenas um vinculo
                    this.vinculosService.selectMunicipio(vinculosMunicipios[0])
                } else if(validSelectedMunicipio){ // mais de um e já selecionado
                    this.vinculosService.selectMunicipio(selectedMunicipio)
                } else {
                    this.router.navigate(['municipio-select'])
                }
                return true
            }))
    }

    getVinculosMunicipio(): Observable<CodigoDescricaoDTO[]> {
        const cpf = localStorage.getItem('matricula');
        return this.gestorService.municipiosVinculados(cpf);
    }

    isSelectedMunicipioValid(vinculos: CodigoDescricaoDTO[], selected:CodigoDescricaoDTO): boolean {
        return selected && vinculos.findIndex((v) => v?.codigo == selected?.codigo) != -1
    }

    isExterno(): Observable<boolean> {
        return this.authService.isExternoUser()
    }

}
