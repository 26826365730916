import { Component, OnDestroy, OnInit, } from '@angular/core';
import { Router } from '@angular/router';
import { UserManagerSettings } from 'oidc-client';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnDestroy, OnInit {

    public nav: any;

    public constructor(public authService: AuthService, public router:Router) {
        this.nav = document.querySelector('nav.navbar');

    }


    public ngOnInit(): any {
    }



    public ngOnDestroy(): any {
    }
}
