import { AfterViewInit, Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements  AfterViewInit {

    showMenu = true;
    constructor(private primengConfig: PrimeNGConfig, private router: Router) {}


    ngAfterViewInit() {
        this.primengConfig.ripple = true;

        this.router.events.subscribe(event => {
            if(event instanceof NavigationEnd) {
                if(event.url === '/unauthorized' || event.urlAfterRedirects.length <= 1) {
                    this.showMenu = false;
                }
            }
        });
    }
}
