import { Injectable } from '@angular/core'
import { CanActivate, Router } from '@angular/router'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { CrsService } from './crs.service'

@Injectable()
export class CrsGuardService implements CanActivate {
    constructor(private crsService: CrsService, private router: Router) {}

    canActivate(): Observable<boolean> {
        return this.crsService.isUsuarioCRS().pipe(
            map((resultado) => {
                if (resultado) {
                    this.router.navigate(['unauthorized'])
                    return false
                } else {
                    return true
                }
            })
        )
    }
}
