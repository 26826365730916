import { OnInit } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'

import { CrudEnum } from '../enum/crud.enum'
import { BaseRoute } from '../models/base-route.model'
import { BaseService } from '../services/base.service'
import { ObjUtil } from '../util/obj.util'
import { BaseDetailComponent } from './base-detail.component'
import { BaseComponent } from './base.component'

/**
 * Classe responsável por conter os métodos padrões para um componente de criação/edição.
 *
 * @date 21/12/2021
 * @author Jhonny Luiz Cabral
 */
export abstract class BaseFormComponent<T, S extends BaseService<T>> extends BaseDetailComponent<T, S> {
    form: FormGroup
    _showChangeCancelar = false
    constructor(public router: Router, public activatedRoute: ActivatedRoute, public baseUrl: BaseRoute, public service: S) {
        super(router, activatedRoute, baseUrl, service)
    }

    /**
     * **PADRÃO - BOTÃO SALVAR**
     * Responsável por realizar a criação/alteração dos dados do formulário. Este
     * método identifica se é uma operação de criação ou alteração através da
     * informação repassada na rota, confirada com o atributo 'operacao'.
     */
    save = () => {
        this.loading()
        if (this._validarFormulario()) {
            if (CrudEnum.UPDATE.equals(this.operacao)) {
                this._update(ObjUtil.isEmpty(this.entity) ? null : this.entity['id'])
            } else {
                this._create()
            }
        } else {
            this.loaded()
        }
    }

    validarFormulario = () => {
        this.loading()
        if (this._validarFormulario()) {
            return true
        } else {
            return false
        }
    }

    abstract setDataFormEdit(): void

    /**
     * Execução de validação do formulário de acordo com os campos obrigatórios.
     *
     * @returns
     */
    _validarFormulario() {
        if (this.form.valid && this.formIsValid()) {
            return true
        } else {
            Object.keys(this.form.controls).forEach((key) => this.form.get(key).markAsDirty())
            this.showErrorsValidation()
            return false
        }
    }

    /**
     * Validação extra para verificação de um formulário inválido.
     *
     * @returns boolean
     */
    formIsValid(): boolean {
        return true
    }

    /**
     * Método adicional para exibição de campos não validos no formulário.
     */
    showErrorsValidation() {}

    /**
     * Executa operação de criação do serviço. Realizando a chamado do método de
     * successo após o retorno metodo:'showMessageSuccess()'.
     */
    private _create() {
        this.addSub(
            this.service.create(this.getDataCreate()).subscribe(
                (res) => {
                    this._entity = res
                    this.loaded()
                    this.showMessageSuccess(res)
                },
                (err) => {
                    console.log(err)
                    this.loaded()
                }
            )
        )
    }

    /**
     * Executa operação de alteração do serviço. Realizando a chamado do método de
     * successo após o retorno metodo:'showMessageSuccess()'.
     */
    private _update(id: number) {
        const data = this.getDataUpdate()
        if (data !== null) {
            this.addSub(
                this.service.update(data, ObjUtil.isEmpty(this.entity) ? null : this.entity['id']).subscribe(
                    (res) => {
                        this._entity = res
                        this.loaded()
                        this.showMessageSuccess(res)
                    },
                    (err) => {
                        console.log(err)
                        this.loaded()
                    }
                )
            )
        } else {
            this.loaded()
        }
    }

    /**
     * Método responsável por retornar o objeto a ser criado.
     *
     * @returns entidade do tipo: T
     */
    abstract getDataCreate(): T

    /**
     * Método responsável por retornar o objeto a ser alterado. Por padrão
     * ele retornará o mesmo método de criação, caso haja alguma mudança de
     * dados, é necessário sua reescrita.
     *
     * @returns entidade do tipo: T
     */
    public getDataUpdate(): T {
        return this.getDataCreate()
    }

    /**
     * Método responsável por exibir a mensagem de sucesso ao criar/editar um
     * registro.
     *
     * @param entity
     */
    abstract showMessageSuccess(entity: T)

    /**
     * **PADRÃO - BOTÃO CANCELAR**
     * Responsável por voltar a pagina de listagem. Caso o formulário tenha
     * sofrido alguma alteração o mesmo irá exibir um alerta de confirmação
     * de cancelamento, representado pela implementação do método
     * openConfirmCancelarDialog();
     */
    /* public cancelar(): void {
    if (this.formHaveModifications()) {
      this.openConfirmCancelarDialog();
    } else {
      this.redirectCancelar();
    }
  }*/

    public cancelar(): void {
        this.router.navigate(['unauthorized'])
    }

    /**
     * Redirecionamento para a rota de listagem.
     */
    public redirectCancelar = () => {
        this.router.navigate([this.baseUrl.url, 'list'])
    }

    /**
     * Dialog de Confirmação de Cancelamento. Implemente este método para
     * exibir o dialog de alerta, caso o formulário tenha sofrido alguma
     * alteração.
     */
    abstract openConfirmCancelarDialog()

    /**
     * Este método verifica se o formulário sofreu alguma alteração,
     * utilizandos da implementação 'dirty' disponível nos inputs do angular.
     *
     * @returns boolean
     */
    public formHaveModifications(): boolean {
        return Object.keys(this.form.controls).filter((key) => this.form.get(key).dirty).length > 0 || this._showChangeCancelar
    }

    public getCaracteres(field: string): number {
        const value = this.form.get(field).value
        return value ? Number.parseInt(value.length, 10) : 0
    }

    public fieldMessageRequired(field): boolean {
        return this.form.get(field).invalid && this.form.get(field).dirty
    }

    abstract initForm(): void

    /**
     * Método responsável por setar a informação de que houve alteração na tela. Usado para mostar o modal de cancelar.
     *
     * @param value
     */
    public changeShowCancelar(value: boolean): void {
        this._showChangeCancelar = value
    }

    /**
     * Utilize este método para resgatar o valor de um campo do formulário.
     *
     * @param field
     * @returns value
     */
    public getValue(field: string): any {
        return this.form.get(field).value
    }

    /**
     * Método responsável, por setar um valor em um campo do formulário.
     *
     * @param field
     * @param value
     */
    public setValue(field: string, value: any): void {
        this.form.get(field).setValue(value)
    }

    /**
     * Método responsável, por setar um valor em um campo do formulário, porém sem emitir um evento de 'change'.
     *
     * @param field
     * @param value
     */
    public setValueNoEvent(field: string, value: any): void {
        this.form.get(field).setValue(value, { onlySelf: false, emitEvent: false })
    }

    /**
     * Realiza a comparação de valor para um campo do formulário.
     *
     * @param field
     * @param value
     * @returns
     */
    public fieldEquals(field: string, value: any): boolean {
        return this.form.get(field).value == value
    }

    /**
     * Método responsável por carregar a última alteração do usuário.
     */
    public loadLastUpdateUser(): void {
        this.addSub(
            this.service.getLastUpdateUser().subscribe((res) => {
                this.lastUpdateUser = res
            })
        )
    }
}
