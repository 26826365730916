<div class="common-page d-flex justify-content-center align-items-center">
    <!-- Conteudo -->
    <div class="page-card d-flex flex-column justify-content-around" [ngClass]="{'loading-content': isLoading}">
        <div class="form-row">
            <div class="form-group col-12 pl-5 pr-5 text-center">
                <label for="inputMunicipio" class="label-class">Selecione o município que você deseja trabalhar</label>
                <select id="inputMunicipio" class="form-control input-class" [(ngModel)]="selectedMunicipioId" (ngModelChange)="onSelectChange($event)">
                    <option selected [ngValue]="null">Escolher...</option>
                    <option *ngFor="let municipio of municipios" [ngValue]="municipio.codigo">
                        {{ municipio.descricao }}
                    </option>
                </select>
            </div>
        </div>
        <div class="row align-self-end">
            <div class="col-12">
                <button type="button" pButton class="p-button-success" 
                    label="Ok" 
                    (click)="onOk()"
                    [disabled]="!selectedMunicipioId"
                >
                </button>
            </div>
        </div>
        <p-progressSpinner
            *ngIf="isLoading"
            [style]="{ width: '50px', height: '50px' }"
            styleClass="custom-spinner"
            strokeWidth="8"
            fill="var(--surface-ground)"
            animationDuration=".8s"
        ></p-progressSpinner>
    </div>
</div>
