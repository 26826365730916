import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { MetismenuAngularModule } from '@metismenu/angular';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { MenubarModule } from 'primeng/menubar';
import { AppComponent } from './../../app.component';
import { CommonComponentsSharedModule } from './../../shared/common-components-shared.module';
import { TemplateComponent } from './template/template.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';

@NgModule({
    declarations: [
        AppComponent,
        UnauthorizedComponent,
        TemplateComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        RouterModule,
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        MetismenuAngularModule,
        MenubarModule,
        CommonComponentsSharedModule,
    ],
    exports: [
        AppComponent,
        UnauthorizedComponent,
        TemplateComponent,
    ]
})
export class CoreModule { }
