import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { take } from "rxjs/operators";
import { ObjUtil } from "src/app/modules/base/util/obj.util";
import { environment } from "src/environments/environment";

const MAX_ALFRESCO_FILESIZE_BYTES:number = toByteFromMB(8);
export const MAX_ALFRESCO_FILESIZE_MB:number = toMBFromByte(MAX_ALFRESCO_FILESIZE_BYTES);
export const MAX_ALFRESCO_FILENAME:number = 50;

function toByteFromMB(value: number): number{
    return value * (1024 ** 2);
}

function toMBFromByte(value: number): number {
    return value / (1024 ** 2); 
}
export class AlfrescoFileNameLengthError implements Error {
    message: string = `Nome do arquivo excede tamanho máximo de ${MAX_ALFRESCO_FILENAME} caracteres. Tente renomear o arquivo.`;
    name: string = 'AlfrescoFileNameLengthError';
    constructor(public stack?:string){}
}

export class AlfrescoFileSizeError implements Error {
    message: string = `Arquivo excede limite de ${MAX_ALFRESCO_FILESIZE_MB}MB.`;
    name: string = 'AlfrescoFileSizeError';
    constructor(public stack?:string){}
}

@Injectable()
export class AlfrescoService {

    constructor(private http: HttpClient) {}

    /**
     * @param file Arquivo para upload
     * @throws {AlfrescoFileNameLengthError} Nome do arquivo excede 50 caracteres
     * @returns 
     */
    uploadArquivoAlfresco(file: File, idFormatoRespostaCriterioMonitoramento?: number): Observable<any> {
        const formData = new FormData()
        const filename = encodeURI(file.name)
        if(file.size > MAX_ALFRESCO_FILESIZE_BYTES){
            return throwError(new AlfrescoFileSizeError())
        }
        if(file.name.length > MAX_ALFRESCO_FILENAME + 3) {
            return throwError(new AlfrescoFileNameLengthError())
        }
        formData.append('file', file)
        formData.append('fileName', filename)
        if(idFormatoRespostaCriterioMonitoramento){
            formData.append('idFormatoRespostaCriterioMonitoramento', idFormatoRespostaCriterioMonitoramento+'')
        }
        return this.http.post<any>(`${environment.serverUrl}documento-alfresco`, formData).pipe(take(1))
    }


    downloadArquivoAlfresco(idArquivo: number): Observable<Blob> {
        const httpHeader = new HttpHeaders({ Accept: 'application/octet-stream' })
        return this.http
            .get(`${environment.serverUrl}documento-alfresco/${idArquivo}`, {
                headers: httpHeader,
                params: this.getExportarParams(null),
                responseType: 'blob',
            })
            .pipe(take(1))
    }

    private getExportarParams(paramsMap: Map<string, any>) {
        if (ObjUtil.isNotNull(paramsMap)) {
            const params = {}
            paramsMap.forEach((v, k) => {
                if (!ObjUtil.isEmpty(v)) {
                    params[k] = v
                }
            })
            return params
        }
    }

}
