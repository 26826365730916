import { Injectable } from '@angular/core';
import { StorageItem } from '../models/storage-item.model';
import { ObjUtil } from '../util/obj.util';

@Injectable({providedIn: 'root'})
export class StorageService {

    private storage = window.localStorage;
    constructor() { }


    public setItem(item: StorageItem<any>) {
        this.storage.setItem(item.id, JSON.stringify(item.data));
    }

    public getItem(id: string): any {
        const item = this.storage.getItem(id);
        return ObjUtil.isEmpty(item) ? null : JSON.parse(item);
    }

    public clearAll() {
        this.storage.clear();
    }
}
