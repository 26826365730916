import { HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { DEFAULT_CURRENCY_CODE, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core'
import localePtBr from '@angular/common/locales/pt'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { ToastrModule } from 'ngx-toastr'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { StorageService } from './modules/base/services/storage.service'
import { CoreModule } from './modules/core/core.module'
import { VinculosGestorGuardService } from './modules/gestor/services/vinculos-gestor-guard.service'
import { VinculosGestorService } from './modules/gestor/services/vinculos-gestor.service'
import { AlfrescoService } from './shared/services/alfresco.service'
import { AuthGuardService } from './shared/services/auth-guard.service'
// App modules/components
import { AuthInterceptor } from './shared/services/auth.interceptor'
import { AuthService } from './shared/services/auth.service'
import { ExternoGuardService } from './shared/services/externo-guard.service'
import { GlobalErrorHandlerService } from './shared/services/global-error-handler.service';
import { CrsGuardService } from './modules/gestores/services/crs-guard.service'
registerLocaleData(localePtBr)
@NgModule({
    declarations: [],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        CoreModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            timeOut: 8000,
            closeButton: true,
            preventDuplicates: true,
        }),
        NgxMaskModule.forRoot(),
    ],
    providers: [
        AuthService,
        StorageService,
        AuthGuardService,
        AlfrescoService,
        VinculosGestorGuardService,
        VinculosGestorService,
        ExternoGuardService,
        CrsGuardService,
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: LOCALE_ID, useValue: 'pt' },
        { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
